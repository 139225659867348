/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { Fragment } from 'react';
import { Box, Button, Center, Flex, Heading as Title, Icon, Image, Stack, Text } from '@chakra-ui/react';
import { CgPill } from 'react-icons/cg';
import { GiHealthNormal } from 'react-icons/gi';
import { Fade } from 'react-awesome-reveal';
import NextLink from 'next/link';

const shapes = [
  { duration: 1500, delay: 100 },
  { duration: 1000, delay: 100 },

  { duration: 500, delay: 200 },
  { duration: 500, delay: 200 },

  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 100 },

  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 300 }
];

const Heading = () => (
  <Box as='header' className='header alter2-header section'>
    <Box className='shapes-container' overflow='hidden' mt='-64px'>
      {/* diagonal shapes */}
      {shapes.map((shape, i) => (
        /* <Fade key={i} direction=up>
          <div
            className='shape shape-animated shape-diagonal'
            duration={shape.duration}
            delay={shape.delay}
          />
        </Fade> */
        <Fragment key={i}>
          <Fade
            direction='up'
            key={i}
            className='shape shape-animated shape-diagonal'
            duration={shape.duration}
            delay={shape.delay}
          />
        </Fragment>
      ))}

      {/* animated shapes */}
      {/* <div className='animation-shape shape-ring animation--clockwise' /> */}
      <div className='animation-shape shape-circle shape-circle-1 animation--anti-clockwise' />
      <div className='animation-shape shape-circle shape-circle-2 animation--clockwise' />
      <div className='animation-shape shape-icon animation--clockwise'>
        <div className='animation--rotating'>
          <Icon as={CgPill} color='orange.300' boxSize='2em' />
        </div>
      </div>

      <div className='animation-shape shape-icon animation--rotating-diagonal'>
        <div className='animation--rotating'>
          <Icon as={CgPill} color='gray.300' boxSize='3em' />
        </div>
      </div>
      <div className='animation-shape shape-diamond animation--anti-clockwise'>
        <div className='animation--rotating'>
          <Icon as={GiHealthNormal} color='gray.300' boxSize='3em' />
        </div>
      </div>

      {/* static shapes */}
      <Box className='static-shape shape-ring-1' borderColor='orange.400' />
      <Box className='static-shape shape-ring-2' borderColor='orange.600' />

      <div className='static-shape shape-circle shape-circle-1'>
        <Fade direction='right'>
          <div />
        </Fade>
      </div>

      <div className='static-shape shape-circle shape-circle-2'>
        <Fade direction='right' delay={500}>
          <div />
        </Fade>
      </div>

      <div className='static-shape pattern-dots-1' />
      <div className='static-shape pattern-dots-2' />

      {/*  main shape */}
      <div className='static-shape background-shape-main' />

      {/*  ghost shapes */}
      <div className='static-shape ghost-shape ghost-shape-1' />
    </Box>

    <Flex
      gap={0}
      maxW={{ base: 'xl', md: '7xl' }}
      mx='auto'
      px={{ base: '6', md: '8' }}
      py={{ base: '6', md: '10' }}
      direction={{ base: 'column', lg: 'row' }}
    >
      <Box maxWidth={{ base: '95%', lg: '50%' }} zIndex={1} mb={{ base: 6, md: 0 }}>
        <Title as='h1' size={{ base: 'md', md: 'xl' }} mt={4} maxW='xl'>
          Personalised health subscriptions delivered to your door
        </Title>
        <Text color='gray.500' my={8} fontSize='xl'>
          Vitamin Club simplifies your health journey, delivering dietitian certified, personalised vitamins for you and
          your family.
        </Text>
        <Stack spacing={{ base: 4, md: 10 }} direction={{ base: 'column', md: 'row' }}>
          <Button as={NextLink} href='/quiz/' size='lg' color='white' colorScheme='orange' mt={8}>
            Complete a quick quiz
          </Button>
          <Text pt={{ base: 0, md: 10 }} color='gray.400'>
            Join 5,000+ customers today!
          </Text>
        </Stack>
      </Box>
      <Center justifyContent='center' textAlign='center'>
        <Image
          src='/images/hero.webp'
          w={600}
          h='auto'
          mx='auto'
          // loading='lazy' // TODO: Investigate: Causes not loading issues on older Safari browsers
          alt='Personalised boxes of sachets'
          fallbackSrc='/images/hero.png'
          fallbackStrategy='onError'
        />
      </Center>
    </Flex>
  </Box>
);

export default Heading;
