import { Box, Flex, Heading } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Testimonial from './Testimonial';

export const testimonials = [
  {
    id: '7',
    name: 'Shaina-Rae',
    testimonial: `I have been taking my personalised vitamins everyday for the past month. To say I absolutely love 
    them would be an understatement! I have noticed a significant boost in my energy levels and overall well-being. 
    The thoughtfulness behind each personalised vitamin package is incredible. I love the attention to detail. My daily 
    routine feels complete with Vitamin Club and I highly recommend them to anyone looking to enhance their health and 
    vitality!`,
    url: 'https://www.instagram.com/hey_shainarae/',
    imageSrc: '/images/testimonials/shaina-rae.jpg',
    jobTitle: 'Radio presenter',
    date: '2024-06-12'
  },
  {
    id: '1',
    name: 'Michelle Sky Hayward',
    testimonial:
      "Vitamin Club has been a game changer for me! For the first time ever, I'm taking my vitamins every day and I " +
      "feel amazing. Personalised, delivered to my door and convenient to take daily and travel with, it's perfect!",
    url: 'https://www.instagram.com/michelleskyhayward/',
    imageSrc: '/images/testimonials/michelle-sky-hayward.webp',
    jobTitle: 'Kitesurfer',
    date: '2022-01-12'
  },
  {
    id: '2',
    name: 'Nicole Hoffmann',
    testimonial:
      'Loving my subscription from Vitamin Club - such an easy way to ensure my daily needs are met with the best ' +
      'quality vitamins and minerals!',
    url: 'https://www.instagram.com/hoffmann_nikki/',
    imageSrc: '/images/testimonials/nicole-hoffmann.webp',
    jobTitle: 'Fitness Trainer',
    date: '2022-01-12'
  },
  {
    id: '3',
    name: 'Lilian du Plessis',
    testimonial:
      'Vitamin club has come up with the most convenient and fun way to have all my Vitamins in one place! My box ' +
      'comes personalised for me which makes it extra special and easy to travel with. Since starting with Vitamin ' +
      "club I have had more energy and drive in my everyday life. I'd recommend this subscription to everyone",
    url: 'https://www.instagram.com/lildup01/',
    imageSrc: '/images/testimonials/lilian-du-plessis.webp',
    jobTitle: "Olympian/SA Women's Hockey Team",
    date: '2022-02-16'
  },
  {
    id: '4',
    name: 'Bronwyn Oliver-Eksteen',
    testimonial: `The Vitamin Club is a savvy, cool way to get one’s daily supplement needs fulfilled. Making 
      supplementing more than a ‘one size fits all’ experience. I particularly like the daily packs as they are easy 
      to throw into my bag when I’m on the go. What’s more convenient is that I don’t have to check what I need to top 
      up on as they are delivered monthly. Since taking the vitamins I’ve noticed an improvement in my energy levels, 
      quality of sleep and overall physical well-being. The omega 3 supplement, in particular, has helped with joint 
      stiffness. The Vitamin Club supplements are definitely a must for me!`,
    url: 'https://www.instagram.com/thebronnyblog/',
    imageSrc: '/images/testimonials/bronwyn-oliver-eksteen.webp',
    jobTitle: 'Mom, wife and blogger',
    date: '2023-11-02'
  },
  {
    id: '5',
    name: 'Chantal Philander',
    testimonial: `As a working mom to a busy 3 year old boy, worrying about my health & needs is the last thing on my 
      priority list. I have been using Vitamin Club for the last few months and I really love the fact that my vitamins 
      are personalised to my needs. The individualised packaging and custom naming on the box makes me feel very special 
      each time I grab a packet & the box gets delivered straight to my front door. Vitamin Club is the one way that 
      I choose to put myself & my health first.`,
    url: 'https://www.instagram.com/channichic/',
    imageSrc: '/images/testimonials/chantal-philander.webp',
    jobTitle: 'Mom and wife',
    date: '2023-11-07'
  },
  {
    id: '6',
    name: 'Lana Tillack',
    testimonial: `I love that The Vitamin Club offers personalised health subscriptions for my whole family, and that they 
    are packed conveniently for daily life on the go! In our home, there are no excuses for not taking vitamins anymore`,
    url: 'https://www.instagram.com/prettybravemom/',
    imageSrc: '/images/testimonials/lana-tillack.webp',
    jobTitle: 'Influencer',
    date: '2023-11-28'
  },
  {
    id: '7',
    name: 'David Fick',
    testimonial: `Vitamin Club has been an absolute game changer for my busy, on the go lifestyle. Their personalised 
    vitamin packs are built to help keep me going & achieving all my running goals, whilst also still having enough 
    energy to take care of our new baby boy, Ollie. I love the ease of use of this service as I no longer have to worry 
    about my vitamin stacks as its all handled by the awesome team at Vitamin Club.`,
    url: 'https://www.instagram.com/daveyontherun/',
    imageSrc: '/images/testimonials/david-fick.jpg',
    jobTitle: 'Asics Front Runner SA Team Captain',
    date: '2024-07-08'
  }
];

const Testimonials: FC = () => (
  <Box
    id='testimonials'
    as='section'
    className='section our-proposal'
    maxW={{ base: '90%', lg: '7xl' }}
    mx='auto'
    mt={{ base: 20, md: '150px' }}
    px={{ base: '6', md: '8' }}
  >
    <Flex direction='column' align={{ base: 'flex-start', md: 'center' }} maxW='2xl' mx='auto' mb={6}>
      <Heading as='h2' size={{ base: 'md', md: 'lg' }} letterSpacing='tight' textAlign='center'>
        Trusted Testimonials
      </Heading>
    </Flex>

    <Carousel infiniteLoop showArrows={false} showStatus={false} showThumbs={false}>
      {testimonials.map((t) => (
        <Testimonial {...t} key={t.id} />
      ))}
    </Carousel>
  </Box>
);

export default Testimonials;
