import * as React from 'react';
import { Box, BoxProps, Img, Text } from '@chakra-ui/react';

interface Props extends BoxProps {
  name: string;
  jobTitle?: string;
  imageSrc: string;
  url?: string;
}

export const Quotee = (props: Props) => {
  const { name, jobTitle, imageSrc, ...boxProps } = props;
  return (
    <Box {...boxProps}>
      <Img src={imageSrc} mx='auto' objectFit='cover' width={175} height={175} rounded='full' alt={name} />
      <Box mt='3'>
        <Text as='cite' fontStyle='normal' fontWeight='bold'>
          {name}
        </Text>
        {jobTitle && (
          <Text fontSize='sm' color='gray.500'>
            {jobTitle}
          </Text>
        )}
      </Box>
    </Box>
  );
};
