import React from 'react';
import { DarkMode } from '@chakra-ui/react';
import { LocalBusinessJsonLd, NextSeo, ProductJsonLd } from 'next-seo';
import dynamic from 'next/dynamic';
import dayjs from 'dayjs';
import Head from '../components/Head';
import Heading from '../components/alter2/Heading';
import { FooterWithNewsletter } from '../components/chakraUIPro/FooterWithNewsletter';
import { Navbar } from '../components/chakraUIPro/Navbar';
import { testimonials } from '../components/Testimonials';
import { DEFAULT_PROFILE_PRICE } from '../contexts/ShopContext';

const QualityPromise = dynamic(() => import(/* webpackPrefetch: true */ '../components/QualityPromise'));
const Proposal = dynamic(() => import(/* webpackPrefetch: true */ '../components/alter1/Proposal'));
const FeaturesCard = dynamic(() => import(/* webpackPrefetch: true */ '../components/FeaturesCard'));
const PowerfulFeatures = dynamic(() => import(/* webpackPrefetch: true */ '../components/shared/PowerfulFeatures'));
const Testimonials = dynamic(() => import(/* webpackPrefetch: true */ '../components/Testimonials'));
const PricingWithFlushedCards = dynamic(
  /* webpackPrefetch: true */
  () => import('../components/chakraUIPro/PricingWithFlushedCards/PricingWithFlushedCards')
);

const HomePage: React.FC = () => {
  const reviews = testimonials.map((review) => ({
    author: {
      type: 'Person',
      name: review.name,
      url: review.url
    },
    reviewBody: review.testimonial,
    reviewRating: {
      ratingValue: '5',
      bestRating: '5'
    },
    datePublished: review.date
  }));

  return (
    <>
      <Head />
      <NextSeo
        title='Personalised health subscriptions delivered to your door'
        description='Vitamin Club simplifies your health journey by delivering dietitian certified, convenient and
        personalised vitamins for you and your family.'
        canonical='https://vitaminclub.co.za/'
        openGraph={{
          url: 'https://vitaminclub.co.za/',
          title: 'Vitamin Club - Personalised Vitamins for You and Your Family',
          description:
            // eslint-disable-next-line max-len
            'Vitamin Club simplifies your health journey by delivering dietitian certified, convenient and' +
            ' personalised vitamins for you and your family.',
          images: [
            {
              url: 'https://vitaminclub.co.za/images/product.jpg',
              width: 800,
              height: 800,
              alt: 'Vitamin Club'
            }
          ]
        }}
      />

      <LocalBusinessJsonLd
        type='HealthAndBeautyBusiness'
        id='https://vitaminclub.co.za/'
        name='Vitamin Club'
        // eslint-disable-next-line max-len
        description='Vitamin Club simplifies your health journey; delivering dietitian certified, personalised vitamins for you and your family.'
        url='https://vitaminclub.co.za/'
        sameAs={[
          'https://www.facebook.com/VitaminClub',
          'https://www.instagram.com/VitaminClub',
          'https://twitter.com/VitaminClub'
        ]}
        rating={{
          ratingValue: '5',
          ratingCount: '5'
        }}
        address={{
          // streetAddress: 'STREET_ADDRESS', // Replace with actual street address
          // addressLocality: 'LOCALITY', // Replace with actual locality
          // addressRegion: 'REGION', // Replace with actual region
          // postalCode: 'POSTAL_CODE', // Replace with actual postal code
          addressCountry: 'South Africa'
        }}
        images={['https://vitaminclub.co.za/images/product.jpg']}
      />

      <ProductJsonLd
        productName='Personalised Vitamin Subscription'
        images={['https://vitaminclub.co.za/images/product.jpg']}
        description='Personalised vitamins tailored to your unique needs.'
        brand='Vitamin Club'
        manufacturerName='Vitamin Club'
        offers={[
          {
            price: DEFAULT_PROFILE_PRICE,
            priceCurrency: 'ZAR',
            url: 'https://vitaminclub.co.za/',
            availability: 'http://schema.org/InStock',
            priceValidUntil: dayjs().add(6, 'months').format('YYYY-MM-DD'),
            shippingDetails: {
              '@type': 'OfferShippingDetails',
              shippingRate: {
                '@type': 'MonetaryAmount',
                value: '0',
                currency: 'ZAR'
              },
              shippingDestination: {
                '@type': 'DefinedRegion',
                addressCountry: 'ZA'
              },
              deliveryTime: {
                '@type': 'ShippingDeliveryTime',
                handlingTime: {
                  '@type': 'QuantitativeValue',
                  minValue: 1,
                  maxValue: 1,
                  unitCode: 'DAY'
                },
                transitTime: {
                  '@type': 'QuantitativeValue',
                  minValue: 1,
                  maxValue: 7,
                  unitCode: 'DAY'
                }
              }
            }
          }
        ]}
        reviews={reviews}
        aggregateRating={{
          ratingValue: '5',
          reviewCount: '6' // TODO: Update with review count
        }}
      />

      <DarkMode>
        <Navbar />
      </DarkMode>

      <Heading />

      <FeaturesCard />

      <Proposal />

      <PowerfulFeatures />

      <QualityPromise />

      <Testimonials />

      <PricingWithFlushedCards />

      <FooterWithNewsletter />
    </>
  );
};

export default HomePage;
