import React, { FC } from 'react';
import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { ImQuotesLeft } from 'react-icons/im';
import Link from 'next/link';
import { Quotee } from './chakraUIPro/Quotee';

interface TestimonialProps extends BoxProps {
  testimonial: string;
  name: string;
  jobTitle?: string;
  url?: string;
  imageSrc?: string;
}

const Testimonial: FC<TestimonialProps> = ({ testimonial, name, jobTitle, imageSrc, url, ...props }) => (
  <Flex direction='column' align='center' textAlign='center' {...props}>
    <Box as={ImQuotesLeft} color='gray.300' fontSize='3xl' mx='auto' />
    <Text
      fontSize={{ base: testimonial.length < 400 ? 'xl' : 'lg', md: testimonial.length < 400 ? '2xl' : 'lg' }}
      fontWeight='medium'
      mt='6'
    >
      &ldquo;{testimonial}&rdquo;
    </Text>
    <Link href={url || '#'} target='_blank'>
      <Quotee name={name} jobTitle={jobTitle} imageSrc={imageSrc || ''} mt='8' />
    </Link>
  </Flex>
);

export default Testimonial;
